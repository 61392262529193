@charset "utf-8";


	$col_width : 100/12/100;
	$col_1  : percentage($col_width);
	$col_2  : percentage($col_width*2);
	$col_3  : percentage($col_width*3);
	$col_4  : percentage($col_width*4);
	$col_5  : percentage($col_width*5);
	$col_6  : percentage($col_width*6);
	$col_7  : percentage($col_width*7);
	$col_8  : percentage($col_width*8);
	$col_9  : percentage($col_width*9);
	$col_10 : percentage($col_width*10);
	$col_11 : percentage($col_width*11);
	$col_12 : percentage($col_width*12);
	$col_per5 : percentage(100/5/100);


.fw {

// ---------------------------------------------------------------------------
//
// Base
//
// ---------------------------------------------------------------------------

	// ----------------------------------------------------------
	// BS - 1 Margin / Padding
	// ----------------------------------------------------------

		@for $i from 0 through 10 {
			.mt#{$i * 5}  { margin-top: 5px * $i !important; }
		}

		@for $i from 0 through 10 {
			.mr#{$i * 5}  { margin-right: 5px * $i !important; }
		}

		@for $i from 0 through 10 {
			.mb#{$i * 5}  { margin-bottom: 5px * $i !important; }
		}

		@for $i from 0 through 10 {
			.ml#{$i * 5}  { margin-left: 5px * $i !important; }
		}

		@for $i from 0 through 10 {
			.pt#{$i * 5}  { padding-top: 5px * $i !important; }
		}
		@for $i from 0 through 10 {
			.pr#{$i * 5}  { padding-right: 5px * $i !important; }
		}
		@for $i from 0 through 10 {
			.pb#{$i * 5}  { padding-bottom: 5px * $i !important; }
		}
		@for $i from 0 through 10 {
			.pl#{$i * 5}  { padding-left: 5px * $i !important; }
		}


		@for $i from 0 through 10 {
			.mg#{$i * 5}  { margin: 5px * $i !important; }
		}

		@for $i from 0 through 10 {
			.pd#{$i * 5}  { padding: 5px * $i !important; }
		}


	// ----------------------------------------------------------
	// BS - 2 - 1 Align
	// ----------------------------------------------------------
		.textL { text-align: left !important; }
		.textC { text-align: center !important; }
		.textR { text-align: right !important; }


	// ----------------------------------------------------------
	// BS - 2 - 2 Text Color
	// ----------------------------------------------------------
		.textRed { color:$red !important;}
		.textBlue { color:$blue !important;}
		.textWhite { color:#FFF !important;}
		.textAqua { color:$aqua !important;}
		.textYellow { color:$yellow !important;}
		.textGreen { color:$green !important;}

	// ----------------------------------------------------------
	// BS - 2 - 3 Letter Spacing
	// ----------------------------------------------------------
		.spaceS { letter-spacing: -0.02em !important; }
		.spaceM { letter-spacing: 0.03em !important;}
		.spaceL { letter-spacing: 0.08em !important;}

	// ----------------------------------------------------------
	// BS - 3 - 1 Font Size
	// ----------------------------------------------------------
		.fontL { @include fontSize(16); }
		.fontM { @include fontSize(14); }
		.fontS { @include fontSize(11); }

	// ----------------------------------------------------------
	// BS - 3 - 2 Font Weight
	// ----------------------------------------------------------
		.fontB {font-weight: bold !important;}


	// ----------------------------------------------------------
	// border
	// ----------------------------------------------------------
		.solidT { border-top: 1px solid $main_color; }
		.solidL { border-left: 1px solid $main_color; }
		.solidB { border-bottom: 1px solid $main_color; }
		.solidR { border-right: 1px solid $main_color; }

		.dottedT { border-top: 1px dotted $main_color; }
		.dottedL { border-left: 1px dotted $main_color; }
		.dottedB { border-bottom: 1px dotted $main_color; }
		.dottedR { border-right: 1px dotted $main_color; }

		.solidGT { border-top: 1px solid $black20; }
		.solidGL { border-left: 1px solid $black20; }
		.solidGB { border-bottom: 1px solid $black20; }
		.solidGR { border-right: 1px solid $black20; }

		.dottedGT { border-top: 1px dotted $black20; }
		.dottedGL { border-left: 1px dotted $black20; }
		.dottedGB { border-bottom: 1px dotted $black20; }
		.dottedGR { border-right: 1px dotted $black20; }

		.left {float: left;}
		.right {float: right;}


	// ----------------------------------------------------------
	// BS - 4 ul,ol
	// ----------------------------------------------------------
	ul {

		// BS - 4 - 1 disc
		// -----------------------------------------------------
		&.disc {
			line-height: 140%;
			>li {
				margin-bottom: 5px;
				text-indent: -1em;
				margin-left: 1em;
				&:before {
					content: "・";
				}
			}
		}

		// BS - 4 - 2 notice
		// -----------------------------------------------------
		&.notice {
			line-height: 140%;
			>li {
				margin-bottom: 5px;
				text-indent: -0.5em;
				margin-left: 0.5em;
				padding-left: 0.5em;
				&:before {
					content: "※";
					display: inline-block;
					width: 0.5em;
				}
			}
		}
	}

	ol {

		// BS - 4 - 3 Number
		// -----------------------------------------------------
		line-height: 140%;
		list-style-type: decimal;
		>li {
			margin-left: 1.7em;
			margin-bottom: 5px;
		}

		// BS - 4 - 4 ()
		// -----------------------------------------------------
		&.bracket {
			list-style-type: none;
			>li {
				text-indent: -2.5em;
				margin-left: 2em;
			}
		    @for $i from 0 through 20 {
		        >li:nth-child(#{$i + 1}) {
		        	&:before {
		        		content: "（#{$i + 1}）";
		        	}
		        }
		    }
		}
	}

	// ----------------------------------------------------------
	// BS - 5 Table
	// ----------------------------------------------------------
		table {
			border-collapse: collapse;
			border-spacing: 0;
			th,td {
				padding: 15px;
				&.bgGray { background: $black0 !important; color: $black; }
				&.col1 { width:$col_1; }
				&.col2 { width:$col_2; }
				&.col3 { width:$col_3; }
				&.col4 { width:$col_4; }
				&.col5 { width:$col_5; }
				&.col6 { width:$col_6; }
				&.col7 { width:$col_7; }
				&.col8 { width:$col_8; }
				&.col9 { width:$col_9; }
				&.col10 { width:$col_10; }
				&.col11 { width:$col_11; }
				&.col12 { width:$col_12; }
				&.colFifth { width:$col_per5; }// 1/5
			}

			&.horizon {
				width: 100%;
				th,td { border-bottom: 1px solid $black20; }
				th { font-weight: bold; }
				thead {
					th,td { border-bottom: 2px solid $black20; }
				}
			}

			&.vertical {
				width: 100%;
				th { border-right: 2px solid $black20; font-weight: bold; }
				td { border-right: 1px solid $black20;
					&:last-child { border-right: none; }
				}
			}

			&.around {
				width: 100%;
				th { border: 1px solid $black20; font-weight: bold; }
				td { border: 1px solid $black20; }
				thead {
					th,td { border-bottom: 2px solid $black20; }
				}
			}

			&.stripe {
				width: 100%;
				tbody {
					tr:nth-child(odd) {
						th,td { background: $black0; }
					}
				}
			}

			&.cursor {
				width: 100%;
				tbody {
					tr {
						&:hover {
							td { background: $black10; }
							&:nth-child(odd) {
								td { background: $black10 !important; }
							}
						}
					}
				}
			}
			&.cellBreak {
				width: 100%;
				th { background:  $black0; color: $black; }
			}
		}


	// ----------------------------------------------------------
	// BS - 6 Box
	// ----------------------------------------------------------

		// BS - 6 - 1 Background
		// -----------------------------------------------------
		.box {
			background: $black0;
			padding: 20px;
		}

		// BS - 6 - 1 Border
		// -----------------------------------------------------
		.boxSolid {
			border: 1px solid $black20;
			padding: 20px;
		}

		// BS - 6 - 3 Dotted
		// -----------------------------------------------------
		.boxDotted {
			border: 1px dotted $black20;
			padding: 20px;
		}

		// BS - 6 - 4 Dashed
		// -----------------------------------------------------
		.boxDashed {
			border: 1px dashed $black20;
			padding: 20px;
		}


	// ----------------------------------------------------------
	// BS - 7 Column
	// ----------------------------------------------------------


		// BS - 7 - 1 Defalt
		// -----------------------------------------------------
		.col {
			display:block;

			&:after {
				content: "";
				display: block;
				clear: both;
				height: 0;
				visibility: hidden;
				overflow: hidden;
				font-size: 0.1em;
				line-height: 0;
			}
			>div,>p {
				//overflow: hidden;
			}

			margin: 0 (-1)*($baseGutter/2)+px $colMargin + px (-1)*($baseGutter/2)+px;
			>.col1 { width:$col_1; }
			>.col2 { width:$col_2; }
			>.col3 { width:$col_3; }
			>.col4 { width:$col_4; }
			>.col5 { width:$col_5; }
			>.col6 { width:$col_6; }
			>.col7 { width:$col_7; }
			>.col8 { width:$col_8; }
			>.col9 { width:$col_9; }
			>.col10 { width:$col_10; }
			>.col11 { width:$col_11; }
			>.col12 { width:$col_12; }
			>.colFifth { width:$col_per5; }// 1/5

			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				padding-left: ($baseGutter/2)+px;
				padding-right: ($baseGutter/2)+px;
				float:left;
			}


		// BS - 7 - 2 Margin Setting
		// -----------------------------------------------------
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colMg#{$num * 1} {
					margin: 0 (-1)*($num/2)+px $colMargin + px (-1)*($num/2)+px;
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						padding-left: ($num/2)+px;
						padding-right: ($num/2)+px;
					}
				}
			}
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colMb#{$num * 1} {
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						margin-bottom: $num + px;
					}
				}
			}


		// BS - 7 - 3 Table Layout
		// -----------------------------------------------------

			&.table {
				display:table;
				width: 100%;
				table-layout: fixed;
				margin: 0 0 $colMargin + px;
			}
			.tableCell {
				float: inherit !important;
				display: table-cell;
				vertical-align: middle;
			}

			>.left,>.right {
			//	margin: 0 ($baseGutter/2)+px 0 ($baseGutter/2)+px;
			}
			>.left {float: left;}
			>.right {float: right;}

		}


	// ----------------------------------------------------------
	// EC - 8 Btn
	// ----------------------------------------------------------

		.btn {
			display: inline-block;
			padding: 12px 15px;
			line-height: 1;
			text-align: center;
			color: $black;
			letter-spacing: 0.05em;
			border: 1px solid $black20;
			border-radius: 2px;
			background: #FFF;
			-moz-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			&.arrow {
				background-position: left center;
				background-repeat: no-repeat;
			}
			&:before {
				background-position: left center;
				background-repeat: no-repeat;
			}
			&.active {
				border: 1px solid $main_color;
			}
			&.btnL {
				@include fontSize(16);
				padding: 13px 30px 12px !important;
			}
			&.bgColor {
				background-color: $main_color;
				border: none;
				color: #FFF;
			}
			&.bgGray {
				background-color: $black50;
				border: none;
				color: #FFF;
			}
			&.bgRed {
				background-color: $red;
				border: none;
				color: #FFF;
			}
			&.bgBlue {
				background-color: $blue;
				border: none;
				color: #FFF;
			}
			&.bgYellow {
				background-color: $yellow;
				border: none;
				color: #FFF;
			}
			&.bgGreen {
				background-color: $green;
				border: none;
				color: #FFF;
			}
			&.bgColor,&.bgGray,&.bgRed,&.bgBlue,&.bgYellow,&.bgGreen {
				&.arrow,&.arrowRight,&.arrowBack,&.arrowBackRight,&.arrowTop,&.arrowTopRight,&.arrowBottom,&.arrowBottomRight {
					&:before,&:after { border-color: #FFF; }
				}
			}
		}
		a.btn,button.btn {
			cursor: pointer;
			//@include transition(0.2);
			text-decoration: none !important;
			&:hover {
				//@include opacity(0.8);
				text-decoration: none;
			}
		}
		button.btn {
			font-family: $base_font;
		}


		// ----------------------------------------------------------
		// EC - 9 Arrow
		// ----------------------------------------------------------
		.arrow, .arrowRight, .arrowBack, .arrowBackRight,.arrowTop,.arrowTopRight,.arrowBottom,.arrowBottomRight {
			display: inline-block;
		}
		.arrow {
			@include arrow($color:$main_color,$size:6);
		}
		.arrowRight {
			@include arrow($color:$main_color,$size:6,$layout:right);
		}
		.arrowBack {
			@include arrow($color:$main_color,$size:6,$direction:left);
		}
		.arrowBackRight {
			@include arrow($color:$main_color,$size:6,$layout:right,$direction:left);
		}
		.arrowTop {
			@include arrow($color:$main_color,$size:6,$direction:top);
		}
		.arrowTopRight {
			@include arrow($color:$main_color,$size:6,$layout:right,$direction:top);
		}
		.arrowBottom {
			@include arrow($color:$main_color,$size:6,$direction:bottom);
		}
		.arrowBottomRight {
			@include arrow($color:$main_color,$size:6,$layout:right,$direction:bottom);
		}

}//.fw
