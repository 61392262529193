
// Font Family
//------------------------------------------------------------------------------
	//webfont
	@font-face {
		font-family: 'SourceHanSansJP-Light';
		src: url('/common/font/SourceHanSansJP-Light.otf');
	}
	@font-face {
		font-family: 'SourceHanSansJP-Medium';
		src: url('/common/font/SourceHanSansJP-Medium.otf');
	}

	//SourceHanSansJP
	$base_font: 'SourceHanSansJP-Light','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	$base_font_bold: 'SourceHanSansJP-Medium','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;

	//Meiryo
	$sub_font: 'メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;


// Font Size
//------------------------------------------------------------------------------
	$base_font_size: 16;


// Line height
//------------------------------------------------------------------------------
	$base_line_height: 168%;


// Line height
//------------------------------------------------------------------------------
	$header_height: 120px;
	$header_height_fix: 60px;
	$header_height_tb: 60px;
	$header_height_sp: 60px;


// Color
//------------------------------------------------------------------------------
	//Main color
	$main_color: #005bac;
	$sub_color: #ed8223;

	//Link color
	$link_color: #4d5155;

	//Other
	$black: #4d5155;

	$black100: #000;
	$black90: #333;
	$black80: #504f4f;
	$black70: #666;
	$black60: #7d7d7d;
	$black50: #999;
	$black40: #888888;
	$black30: #CCC;
	$black20: #bfbfbf;
	$black10: #EEE;
	$black0: #f3f3f3;

	$blue: $main_color;
	$aqua: #eff3f6;
	$white: #fff;
	
	//未使用
	$red: #ea0a0a;
	$yellow: #ead805;
	$green: #9abc50;


// Width
//------------------------------------------------------------------------------

	//全体横幅
	$main_width: 1024;

	//サイドコンテンツ横幅
	$side_width: 220;

	//メインコンテンツとサイドコンテンツのmargin
	$base_margin: 40;

	//メインコンテンツ横幅
	$contents_width: 1024;

	// section
	//------------------------------------------------------------------------------
		$sectionMt: 70;


// Break Point
//------------------------------------------------------------------------------

	$pc_width: 1024;
	$tb_width: 768;
	$sp_width: 768;
	$device_width: 640;


// Column Width
//------------------------------------------------------------------------------

	//カラム間margin
	$baseGutter: 20;//px

	//カラム Margin Bottom
	$colMargin: 0;//px
