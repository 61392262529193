@charset "utf-8";

.fw {

// ---------------------------------------------------------------------------
//
// Tablet
//
// ---------------------------------------------------------------------------


@media only screen and (max-width: $tb_width + px) {//tb

	// ----------------------------------------------------------
	// BS - 5 Table
	// ----------------------------------------------------------
		table {
			&.cellBreak {
				margin-top: 1px;
				th {
					background: $black0;
				}
				th,td {
					width: 100% !important;
					display: block;
					margin-top: -1px;
				}
			}
		}
		.tableResponsive {
			overflow-x: auto;
			table {
				width: $contents_width + px;
			}
		}


	// ----------------------------------------------------------
	// BS - 7 Column
	// ----------------------------------------------------------

		// BS - 7 - 1 Default
		// -----------------------------------------------------
		.col {

			.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.colFifth {
				width:$col_6;
			}
			>.col1 { width:$col_3; }
			>.col10 { width:$col_10; }
			>.col11 { width:$col_11; }
			>.col12 { width:$col_12; }

			&.notBreak {
				>.col1 { width:$col_1; }
				>.col2 { width:$col_2; }
				>.col3 { width:$col_3; }
				>.col4 { width:$col_4; }
				>.col5 { width:$col_5; }
				>.col6 { width:$col_6; }
				>.col7 { width:$col_7; }
				>.col8 { width:$col_8; }
				>.col9 { width:$col_9; }
				>.col10 { width:$col_10; }
				>.col11 { width:$col_11; }
				>.col12 { width:$col_12; }
				>.colFifth { width:$col_per5; }// 1/5
			}

			&.fit {
				margin: 0 -20px $colMargin + px -20px;
				>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
					//margin-bottom: 10px;
				}
			}

		}

	// ----------------------------------------------------------
	// EC - 8 Btn
	// ----------------------------------------------------------

		.arrow {
			&:before{
				@include fontSize(16);
			}
		}

		.btn {
			&.arrow {
			}
			&:before {
			}
			&.active {
			}
			&.btnL {
				@include fontSize(16);
			}
		}
		a.btn,button.btn {
		}

}//tb




// ---------------------------------------------------------------------------
//
// Smart Phone
//
// ---------------------------------------------------------------------------

@media only screen and (max-width: $sp_width + px) {//sp

	// ----------------------------------------------------------
	// BS - 5 Table
	// ----------------------------------------------------------
		.tableResponsive {
			overflow-x: auto;
			table {
				width: $contents_width + px;
			}
		}

	// ----------------------------------------------------------
	// BS - 7 Column
	// ----------------------------------------------------------

		// BS - 7 - 1 Defalt
		// -----------------------------------------------------
		.col {

			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				width:$col_12;
			}

			&.notBreak {
				>.col1 { width:$col_1; }
				>.col2 { width:$col_2; }
				>.col3 { width:$col_3; }
				>.col4 { width:$col_4; }
				>.col5 { width:$col_5; }
				>.col6 { width:$col_6; }
				>.col7 { width:$col_7; }
				>.col8 { width:$col_8; }
				>.col9 { width:$col_9; }
				>.col10 { width:$col_10; }
				>.col11 { width:$col_11; }
				>.col12 { width:$col_12; }
				>.colFifth { width:$col_per5; }// 1/5
			}

			// BS - 7 - 2 Margin Setting
			// -----------------------------------------------------
			//colSpMg
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colSpMg#{$num * 1} {
					margin: 0 (-1)*($num/2)+px $colMargin + px (-1)*($num/2)+px;
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						padding-left: ($num/2)+px;
						padding-right: ($num/2)+px;
					}
				}
			}
			//colSpMb
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colSpMb#{$num * 1} {
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						margin-bottom: $num + px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}


			// BS - 7 - 5 SP 2Column
			// -----------------------------------------------------
				&.spCol6 {
					.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.colFifth {
						width:$col_6;
					}
				}

		}

		// BS - 7 - 7 Order
		// -----------------------------------------------------
		.flexbox {

			@include flexbox();
			@include flex-direction(column);

			@for $i from 1 through 99 {
				.order#{$i} {
					@include order($i);
				}
			}
			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				float: none;
			}
		}

	// ----------------------------------------------------------
	// EC - 7 Btn
	// ----------------------------------------------------------

		.arrow {
			&:before{
				@include fontSize(16);
			}
		}

		.btn {
			&.arrow {
			}
			&:before {
			}
			&.active {
			}
			&.btnL {
				@include fontSize(16);
			}
		}
		a.btn,button.btn {
		}


}//sp



}//.fw
