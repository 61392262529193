@charset "utf-8";


// ---------------------------------------------------------------------------
//
// Javascript
//
// ---------------------------------------------------------------------------


// JS - 1 j_toggle
// -----------------------------------------------------
.j_toggleBtn,.j_toggle dt {
	cursor: pointer;
	color: $link_color;
	text-decoration: none;
	//@include transition(0.2);
	display: block;
	padding: 14px 10px 12px;
	border: 1px solid $black20;
	//border-radius: 2px;
	line-height: 1;
	background: $black0;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	cursor: pointer;
	@include arrow($color:$main_color,$layout:right,$direction:bottom);
	position: relative;
	&:after {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -6px;
	}
	&:hover {
		text-decoration: none !important;
	}
	&.active {
		@include arrow($color:$main_color,$layout:right,$direction:top);
		&:after {
			margin-top: -3px;
		}
	}
}
.j_toggleDetail,.j_toggle dd {
	padding: 20px;
	border: 1px solid $black20;
	border-top: none;
}

// JS - 2 j_tab
// ----------------------------------------------------------
.j_tab {

	// JS - 2 - 2 Defalt
	// -----------------------------------------------------

	ul {
		&:after{
			content: "";
			display: block;
			clear: both;
			height: 0;
			visibility: hidden;
			overflow: hidden;
			font-size: 0.1em;
			line-height: 0;
		}
		border-bottom: 1px solid $black20;
		margin-bottom: 20px;
		li {
			display: inline-block;
			float: left;
			margin: 0 4px -1px 0;
			.j_tabBtn {
				-moz-user-select: none;
				-webkit-user-select: none;
				-ms-user-select: none;
				border-radius: 1px 1px 0 0;
				border: 1px solid $black20;
				display: block;
				padding: 10px 20px;
				background: $black0;
				color: $black;
				&.active,&:hover {
					color: $main_color;
					background: #FFF;
					border-bottom: 1px solid #FFF;
					text-decoration: none;
				}
			}
		}
	}

	// JS - 2 - 2 Vertical
	// -----------------------------------------------------

	&.vertical {
		&:after{
			content: "";
			display: block;
			clear: both;
			height: 0;
			visibility: hidden;
			overflow: hidden;
			font-size: 0.1em;
			line-height: 0;
		}
		.left {
			border-bottom: none;
			margin-bottom: 0;
			position: relative;
			z-index: 10;
			width: 25%;
			li {
				display: block;
				float: none;
				margin: 0;
				&:first-child {
					a { border-radius: 2px 0 0 0; }
				}
				&:last-child {
					a { border-radius: 0 0 0 2px; }
				}
				.j_tabBtn {
					margin-bottom: 4px;
					display: block;
					padding: 10px;
					margin-right: 4px;
					&:before{
						content: "›";
						display: inline-block;
						margin-right: 2px;
						width: 10px;
						text-align: center;
					}

					&:hover,&.active {
						text-decoration: none;
						margin-right: -1px;
						border: 1px solid $black20;
						border-right: 1px solid #FFF;
					}
				}//.j_tabBtn
			}//li
		}
		.right {
			width: 75%;
		}
	}//.vTab
}


// ----------------------------------------------------------
// JS - 4 Tooltip
// ----------------------------------------------------------

	.j_tip {
		display: inline-block;
	}
	.j_tipDetail {
		padding: 8px 8px 7px;
		border: 1px solid $black40;
		background: #FFF;
		border-radius: 2px;
		z-index: 10;
		line-height: 1;
		@include boxshadow;
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			position: absolute;
		}
		&.left {
			&:before {
				border-bottom: 4px solid transparent;
				border-left: 6px solid $black40;
				border-top: 4px solid transparent;
				top: 50%;
				right: 0;
				margin: -4px -6px 0 0;
			}
		}
		&.top {
			&:before {
				border-top: 6px solid $black40;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				bottom: -6px;
				left: 10px;
			}
		}
		&.right {
			&:before {
				border-bottom: 4px solid transparent;
				border-right: 6px solid $black40;
				border-top: 4px solid transparent;
				top: 50%;
				left: 0;
				margin: -4px 0 0  -6px;
			}
		}
		&.bottom {
			&:before {
				border-bottom: 6px solid $black40;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				top: -6px;
				left: 10px;
			}
		}
	}


// ----------------------------------------------------------
// JS - 5 Popover
// ----------------------------------------------------------
	.j_popover {
		.j_popoverBtn {
			&.popActive {
				&.btn {
					background: $black20;
				}
			}
		}
	}
	.j_popoverDetail {
		padding: 10px;
		border: 1px solid $black20;
		background: #FFF;
		border-radius: 2px;
		z-index: 10;
		line-height: 1;
		@include boxshadow;
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			position: absolute;
		}
		&.left {
			float: inherit;
			&:before {
				border-bottom: 6px solid transparent;
				border-left: 6px solid $black40;
				border-top: 6px solid transparent;
				top: 50%;
				right: 0;
				margin: -6px -6px 0 0;
			}
		}
		&.top {
			&:before {
				border-top: 6px solid $black40;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				bottom: -6px;
				left: 10px;
			}
		}
		&.right {
			float: inherit;
			&:before {
				border-bottom: 6px solid transparent;
				border-right: 6px solid $black40;
				border-top: 6px solid transparent;
				top: 50%;
				left: 0;
				margin: -6px 0 0  -6px;
			}
		}
		&.bottom {
			&:before {
				border-bottom: 6px solid $black40;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				top: -6px;
				left: 10px;
			}
		}
	}


// ----------------------------------------------------------
// JS -  Dropdown
// ----------------------------------------------------------
.j_dropdown {
	display: inline-block;
	.j_dropdownBtn {
		&.dropActive {
			background: $black0;
		}
	}
	.j_dropdownDetail {
		z-index: 10;
		min-width: 180px;
		background: #FFF;
		border-radius: 2px;
		border: 1px solid $black20;
		padding: 8px 0;
		box-shadow: 0px 6px 6px rgba(0,0,0,.175);
		-webkit-box-shadow: 0px 6px 6px rgba(0,0,0,.175);
		a {
			width: 100%;
			display: block;
			padding: 8px;
			&:hover {
				background-color: $black0;
				text-decoration: none;
			}
		}
	}

}//.j_dropdown
