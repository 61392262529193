@charset "utf-8";


@mixin fontSize($size:13) {
	font-size: $size + px;
	font-size: ( $size * 0.0625 ) + rem;
}
@mixin transition($sec:0.1, $ease:linear, $delay:0) {
	transition: all $sec+s $ease $delay+s;
	-webkit-transition: all $sec+s $ease $delay+s;
}
@mixin transSlow {
	transition: all 0.4s linear 0s;
	-webkit-transition: all 0.4s linear 0s;
}
@mixin transition2 {
	transition: all 0.15s cubic-bezier(0.550,0.055,0.675,0.190) 0s;
	-webkit-transition: all 0.15s cubic-bezier(0.550,0.055,0.675,0.190) 0s;
}
@mixin textshadow($x:1, $y:1, $blur:1, $color:rgba(0, 0, 0, 0.1)) {
	text-shadow: $x+px $y+px $blur+px $color;
	-webkit-text-shadow: $x+px $y+px $blur+px $color;
}
@mixin boxshadow($x:1, $y:1, $blur:1, $spread:0, $color:rgba(0, 0, 0, 0.1)) {
	box-shadow: $x+px $y+px $blur+px $spread+px $color;
	-webkit-box-shadow: $x+px $y+px $blur+px $spread+px $color;
}
@mixin none {
	background: none;
	border: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	box-shadow: none;
	color: $black;
	text-align: left;
	font-size: $base_font_size;
	line-height: $base_line_height;
}

@mixin clearfix {
 &:after {
 content: ".";
 display: block;
 height: 0;
 clear: both;
 visibility: hidden;
 }
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}


@mixin percentWidth($myWidth, $maxWidth){
  width: percentage($myWidth / $maxWidth);
}


@mixin arrow($color:#000000, $size:6, $width:1,$layout:left,$direction:right) {
	&:before,&:after {
		display: inline-block;
		border-top: solid $width+px $color;
		border-right: solid $width+px $color;
		vertical-align: middle;
	}
	@if $layout == left {
		&:before {
			margin-right: 8px;
			content: "";
			width: $size+px;
			height: $size+px;
		}
	} @else if $layout == right {
		&:after {
			margin-left: 8px;
			content: "";
			width: $size+px;
			height: $size+px;
		}
	}
	@if $direction == top {
		&:before,&:after { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); margin-top: 1px; }
	} @else if $direction == right {
		&:before,&:after { transform: rotate(45deg); -webkit-transform: rotate(45deg); margin-top: -1px; }
	} @else if $direction == bottom {
		&:before,&:after { transform: rotate(135deg); -webkit-transform: rotate(135deg); margin-top: -5px; }
	} @else if $direction == left {
		&:before,&:after { transform: rotate(-135deg); -webkit-transform: rotate(-135deg); }
	}
}


@mixin arrowAbs($color:#000000, $size:6, $width:1,$layout:left,$position:0,$direction:right) {
	position: relative;
	&:before {
		content: "";
		display: block;
		border-top: solid $width+px $color;
		border-right: solid $width+px $color;
		vertical-align: middle;
		width: $size+px;
		height: $size+px;
		position: absolute;
		top: 50%;
	}
	@if $layout == left {
		&:before {
			left: $position+px;
		}
	} @else if $layout == right {
		&:before {
			right: $position+px;
		}
	}
	@if $direction == top {
		&:before {
			transform: translate(0,-50%) rotate(-45deg);
			-webkit-transform: translate(0,-50%) rotate(-45deg);
		}
	} @else if $direction == right {
		&:before {
			transform: translate(0,-50%) rotate(45deg);
			-webkit-transform: translate(0,-50%) rotate(45deg);
		}
	} @else if $direction == bottom {
		&:before {
			transform: translate(0,-50%) rotate(135deg);
			-webkit-transform: translate(0,-50%) rotate(135deg);
		}
	} @else if $direction == left {
		&:before {
			transform: translate(0,-50%) rotate(-135deg);
			-webkit-transform: translate(0,-50%) rotate(-135deg);
		}
	}
}